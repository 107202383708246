@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #2660ad;
  --secondary-color: #db5443;

  --denim-50: #f3f6fc;
  --denim-100: #e5ecf9;
  --denim-200: #c6d8f1;
  --denim-300: #93b8e6;
  --denim-400: #5993d7;
  --denim-500: #3476c3;
  --denim-600: #2660ad;
  --denim-700: #1e4986;
  --denim-800: #1d3f6f;
  --denim-900: #1d365d;
  --denim-950: #13233e;

  --cinnabar-50: #fdf4f3;
  --cinnabar-100: #fbe7e5;
  --cinnabar-200: #f9d4cf;
  --cinnabar-300: #f4b5ad;
  --cinnabar-400: #eb8a7e;
  --cinnabar-500: #db5443;
  --cinnabar-600: #ca4838;
  --cinnabar-700: #aa392b;
  --cinnabar-800: #8d3227;
  --cinnabar-900: #753027;
  --cinnabar-950: #3f1510;

  .aligncenter > * {
    margin: auto;
    text-align: center;
  }

  .has-text-align-center {
    text-align: center;
  }

  .promo {
    margin-bottom: 0;
    padding: theme('spacing.6');
    border-radius: 8px;
    background-color: rgba(219, 84, 67, .12);
    font-weight: 500;
  }
}

.entry-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.entry-loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary-color);
  animation: loading-bar-beat 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.entry-loading div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.entry-loading div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.entry-loading div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loading-bar-beat {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
